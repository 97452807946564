import * as React from 'react';

import type { HeadProps, PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { CtaBlock } from 'stile-shared/src/components/2020/blocks/CtaBlock';
import { Prose, Section } from 'stile-shared/src/components/2020/common/Primitives';

import { PageLayout } from 'templates/2020/PageLayout';
import { SEO } from 'templates/2020/SEO';

type TermsPageProps = PageProps & {
  data: {
    contractTermsUs: Queries.contractTermsUs;
  };
};

export function Head(props: HeadProps) {
  return (
    <SEO
      title="Terms & Conditions"
      description="By subscribing to our services, you agree with our terms & conditions. Read our terms & conditions here!"
      slug={props.location.pathname}
    />
  );
}

const TermSection = styled(Section)`
  @media (max-width: 768px) {
    margin-top: 32px !important;
  }
  strong {
    font-weight: bold;
  }
  ol {
    list-style-type: lower-alpha;
    li > ol {
      list-style-type: lower-roman;
    }
  }
`;

function TermsPage({ data, ...props }: TermsPageProps) {
  console.log(data);
  return (
    <PageLayout {...props}>
      <TermSection>
        <Prose>
          <div
            dangerouslySetInnerHTML={{
              __html: data.contractTermsUs?.terms?.termsHtml ?? '',
            }}
          />

          {data.contractTermsUs.terms?.revisedOn?.value && (
            <em>
              Last updated:{' '}
              {new Date(data.contractTermsUs.terms?.revisedOn?.value).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
              })}
            </em>
          )}
        </Prose>
      </TermSection>

      <CtaBlock />
    </PageLayout>
  );
}

export const query = graphql`
  query ContractTermsQuery {
    contractTermsUs {
      terms {
        termsHtml
        revisedOn {
          value
        }
      }
    }
  }
`;

export default TermsPage;
